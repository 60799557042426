import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./index.css"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"

const rootElement = document.getElementById("root") as HTMLElement
const reactRoot = ReactDOM.createRoot(rootElement)

reactRoot.render(
	<React.StrictMode>
		<App/>
	</React.StrictMode>
)
